
<template>
  <div>
    <v-menu 
      offset-y 
      no-border
      rounded
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-avatar v-on="on" v-bind="attrs" size="30" color="rgb(60, 44, 129)">
          <!-- <v-img alt="avatar" contain :src="photoUrl" /> -->
          <v-icon size="30" color="white">
            mdi-account-circle
          </v-icon>
        </v-avatar>
      </template>
      <v-card>
        <v-list color="transparent">
          <v-list-item>
            <v-list-item-avatar color="rgb(60, 44, 129)">
              <v-icon dark>
                mdi-account-circle
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="grey--text text--darken-4">{{ userName }}</v-list-item-title>
              <v-list-item-subtitle class="grey--text text--darken-2">{{ userID }}</v-list-item-subtitle>
            </v-list-item-content>

          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <div>
          <div class="d-flex ma-4 align-center justify-center" v-if="isProUser">
            <v-icon color="amber accent-4">mdi-crown</v-icon>
            <h5 class="text-center">Your current plan:Pro Plan</h5>
          </div>
          <div class="d-flex ma-4 align-center justify-center" v-else>
            <v-btn small color="blue" rounded @click='handlePricing'>
              <span class="ma-2 text-capitalize white--text">Upgrade plan</span>
            </v-btn>
          </div>
          <div class="mb-2" v-show='!isProUser'>
            <h5 class="text-center grey--text text--darken-2">Free today: <span class="green--text text--darken-4">{{ translateNum }}</span></h5>
          </div>
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error"
            text
            @click="handleLogout"
            class="text-capitalize"
          >
            Sign out
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'

export default {
  name: 'YA_User',
  data() {
    return {
      userID: '',
      userName: '',
      token: '',
      isLogin: false,
      isProUser: false,
      translateNum: 0,
    }
  },

  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;

    this.$store.watch(
      (state) => state.userMsg.userName, () => {
        this.userName = this.$store.getters.getuserName;
      }, {
        deep: true
      }
    );
    this.userName = this.$store.getters.getuserName;

    this.$store.watch(
      (state) => state.userMsg.isProUser, () => {
        this.isProUser = this.$store.getters.getisProUser;
      }, {
        deep: true
      }
    );
    this.isProUser = this.$store.getters.getisProUser;

    this.$store.watch(
      (state) => state.userMsg.translateNum, () => {
        this.translateNum = this.$store.getters.gettranslateNum;
      }, {
        deep: true
      }
    );
    this.translateNum = this.$store.getters.gettranslateNum;
  },

  mounted() {

  },

  methods: {
    handleLogout() {
      removeLocalStorage('multitranslateUser');
      removeLocalStorage('multitranslateToken');

      this.$store.commit('HandleisLogin', false);
      this.$store.commit('HandleuserID', '');
      this.$store.commit('Handletoken', '');
      this.$store.commit('HandleuserName', '');
      this.$store.commit('HandleisProUser', false);
      this.$store.commit('HandletranslateNum', 0);

      if (this.$router.currentRoute.path !== '/Login') {
        this.$router.push('/Login');
      }
    },

    handlePricing() {
      if (this.$router.currentRoute.path !== '/UserMainPricing') {
        this.$router.push('/UserMainPricing');
      }
    }
  }

};
</script>

<style scoped>

</style>