<template>
  <div>
    <v-container>
        <v-container v-if="isLogin">
          <div class="d-flex justify-center mt-16" v-if="StarLoading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </div>
          <v-card v-for="item in items" :key="item.StarId" hover class="mx-auto ma-4" width="100%" v-else>
              <!-- 输入语言与时间 -->
              <v-container class="d-flex align-center">
                  <div class="ml-2 mt-2 text-caption">{{ item.InputLanguage }}</div>
                  <v-spacer></v-spacer>
                  <div class="mr-2 mt-2 text-caption font-weight-bold grey--text">{{ item.StarTime }}</div>
              </v-container>
              <!-- 翻译前内容 -->
              <v-container class="d-flex align-center grey lighten-4">
                  <div class="ml-4  font-weight-bold">{{ item.InputText }}</div>
              </v-container>
              <v-divider></v-divider>
              <!-- 输出语言与tool -->
              <v-container class="d-flex align-center">
                  <div class="ml-2 mt-2 text-caption">{{ item.TranslateLanguage }}</div>
                  <v-spacer></v-spacer>
                  <v-img :alt="item.TranslateTool" width="20" class="shrink" contain :src="supportedTranslator[item.TranslateTool]" transition="scale-transition" />
                  <div class="font-weight-black ml-2 mr-2">{{ item.TranslateTool }}</div>
                  <v-btn @click="handleDelete(item.StarId)" class="ml-2 mr-2" icon>
                      <v-icon color="darkgrey">mdi-trash-can</v-icon>
                  </v-btn>
              </v-container>
              <!-- 翻译内容 -->
              <v-container class="d-flex align-center">
                  <div class="ml-4">{{ item.TranslateText }}</div>
              </v-container>
          </v-card>
          <!-- 加载中 -->
          <!-- <v-overlay :value="StarLoading"> -->
          
          <!-- </v-overlay> -->
            
        </v-container>
        <!-- 若未登录，请登录 -->
        <v-container v-else class="d-flex align-center justify-center fill-height">
            <h3>Please Login</h3>
            <!--       <v-alert border="left" class="font-weight-bold text-S1" width="40%">
        Please log in
      </v-alert> -->
        </v-container>
    </v-container>
  </div>
</template>

<script>
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import { requestPost } from '@/utils/SystemUtils/request.js';

export default {
  name: 'StarView',

  components: {
  },

  data() {
    return {
      userID: '',
      token: '',
      isLogin: false,

      items: [
          // {
          //   'starId': 'asdfdasf',
          //   'InputLanguage': 'Chinese',
          //   'StarTime': '2022-12-23 23:00:33',
          //   'InputText': '啦啦啦',
          //   'TranslateLanguage': 'English',
          //   'TranslateTool': 'Google',
          //   'TranslateText': 'lalala'
          // }
      ],
      translateTool: "",
      StarLoading: false,
      supportedTranslator: {
          'Bing': require('@/assets/bing.png'),
          'Google': require('@/assets/google.png'),
          'Deepl': require('@/assets/deepl.png'),
          'Baidu': require('@/assets/baidu.png'),
          'Alibaba': require('@/assets/alibaba.png'),
          'Youdao': require('@/assets/youdao.png'),
          'Tencent': require('@/assets/tencent.png'),
          'Papago': require('@/assets/papago.svg'),
          'iTranslate': require('@/assets/itranslate.svg')
      },
    }
  },

  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
        if (this.isLogin) {
          this.handleGetStarMsg();
        }
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;
  },

  mounted() {
  },

  activated() {
    if (this.isLogin) {
      this.handleGetStarMsg();
    }
  },

  methods: {
    async handleGetStarMsg() {
      this.StarLoading = true;
      let request_header = {
        email: getLocalStorage('multitranslateUser'),
        token: getLocalStorage('multitranslateToken')
      };
      let request_json = {};

      let request_url = '/requestGetStarMsg';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        this.$set(this, 'items', res.data);

        this.$store.commit('HandleSnackbar', 'Get star message successed');
        this.StarLoading = false;
      } else {
        this.$store.commit('HandleSnackbar', 'Failed to retrieve star information:' + res.statusInfo);
      }
    },

    async handleDelete(StarId) {
      this.StarLoading = true;
      let request_header = {
        email: getLocalStorage('multitranslateUser'),
        token: getLocalStorage('multitranslateToken')
      };
      let request_json = {
        StarId: StarId
      };

      let request_url = '/requestDeleteStarMsg';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        this.$store.commit('HandleSnackbar', 'delete successed');
        this.StarLoading = false;
        this.handleGetStarMsg()
      } else {
        this.$store.commit('HandleSnackbar', 'Failed to delete:' + res.statusInfo);
      }
    }
  }
};
</script>
