import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

  const state= {
    userMsg: {
      isLogin: false,
      userID: '',
      userName: '',
      isProUser: false,
      translateNum: 0,
      token: '',
    },
    systemMsg: {
      Snackbar: '',
      loadingMsg: "",
      nowRouter: '',
    },
  }

  const getters= {
    // userMsg
    getisLogin() {
      return state.userMsg.isLogin
    },
    getuserID() {
      return state.userMsg.userID
    },
    getuserName() {
      return state.userMsg.userName
    },
    getisProUser() {
      return state.userMsg.isProUser
    },
    gettranslateNum() {
      return state.userMsg.translateNum
    },
    gettoken() {
      return state.userMsg.token
    },

    // systemMsg
    getSnackbar() {
      return state.systemMsg.Snackbar
    },
    getloadingMsg() {
      return state.systemMsg.loadingMsg
    },
    getnowRouter() {
      return state.systemMsg.nowRouter
    },
  }

  const mutations= {
    // userMsg
    HandleisLogin(state, data) {
      state.userMsg.isLogin = data; 
    },
    HandleuserID(state, data) {
      state.userMsg.userID = data; 
    },
    HandleuserName(state, data) {
      state.userMsg.userName = data; 
    },
    HandleisProUser(state, data) {
      state.userMsg.isProUser = data; 
    },
    HandletranslateNum(state, data) {
      state.userMsg.translateNum = data; 
    },
    Handletoken(state, data) {
      state.userMsg.token = data; 
    },

    // systemMsg
    HandleSnackbar(state, data) {
      state.systemMsg.Snackbar = data; 
    },
    HandleloadingMsg(state, data) {
      state.systemMsg.loadingMsg = data; 
    },
    HandlenowRouter(state, data) {
      state.systemMsg.nowRouter = data; 
    },
  }

export default new Vuex.Store({
    mutations:mutations,
    state:state,
    getters:getters,
})