<template>
    <div>
      <v-btn small color="rgb(60, 44, 129)" href="https://buy.stripe.com/00g0279kAfOF4U05km" target="__blank" v-if='isLogin'>
          <span class="ma-2 text-capitalize white--text">Upgrade plan</span>
      </v-btn>

      <v-btn small color="rgb(60, 44, 129)"  @click='handleLogin' v-else>
          <span class="ma-2 text-capitalize white--text">Upgrade plan</span>
      </v-btn>
      <v-snackbar timeout='2000' v-model='snackbar_show' top>{{snackbar_text}}</v-snackbar>
  </div>
</template>

<script>
  
export default {
  name: 'PayLink',
  data(){
    return{
      isLogin:false,
      snackbar_show:false,
      snackbar_text:''
    }
  },

  created() {
      this.$store.watch(
          (state) => state.LoginState,
          () => {
              this.isLogin = this.$store.getters.getLoginState;
          }, {
              deep: true
          }
      );
      this.isLogin = this.$store.getters.getLoginState;
  },
  methods:{
    handleLogin(){
      this.snackbar_show=false
      this.snackbar_show=true
      this.snackbar_text='Login to upgrade plan'
    }
  }


}

</script>
