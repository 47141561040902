<template>
  <v-container>
    <!-- 抬头照片与按钮 -->
    <v-container v-if="$vuetify.breakpoint.smAndDown">
      <v-img src="@/assets/pic_backImg_pro.jpg" width="100%" contain></v-img>
      <v-container class="my-2 py-1 d-flex justify-center">
        <v-btn class='mx-auto mr-4 text-body-2 text-sm-body-2 text-md-body-1 text-lg-h6 text-xl-h6 white--text font-weight-black' color="grey darken-4" rounded @click="handleTranslate">
          <v-icon right dark class="mx-auto mr-4">
            mdi-message-arrow-right
          </v-icon>
          Try Now
        </v-btn>
        <v-btn class='mx-auto ml-4 text-body-2 text-sm-body-2 text-md-body-1 text-lg-h6 text-xl-h6 font-weight-black' outlined rounded @click="handleContact">Contact us</v-btn>
      </v-container>
    </v-container>
    <v-container v-else>
      <!-- <v-img src="@/assets/titleImg_pro.jpg" height="600" contain></v-img> -->
      <v-img src="@/assets/backImg_pro.jpg" contain></v-img>
      <v-container class="my-6 py-4 d-flex justify-center">
        <v-btn class='mx-auto mr-4 text-body-2 text-sm-body-2 text-md-body-1 text-lg-h6 text-xl-h6 white--text font-weight-black' color="grey darken-4" rounded @click="handleTranslate">
          <v-icon right dark class="mx-auto mr-4">
            mdi-message-arrow-right
          </v-icon>
          Try Now
        </v-btn>
        <v-btn class='mx-auto ml-4 text-body-2 text-sm-body-2 text-md-body-1 text-lg-h6 text-xl-h6 font-weight-black' outlined rounded @click="handleContact">Contact us</v-btn>
      </v-container>
    </v-container>
    
    <!-- 1--多翻译平台 -->
    <v-container class="my-10 py-5" >
      <v-container class="d-flex justify-center flex-wrap text-center" v-if="$vuetify.breakpoint.smAndDown">
        <v-img src="@/assets/pic_logo.jpg" height="150" contain></v-img>
        <p class="my-custom-font-explain text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 font-weight-black mt-2">Multi-Translation Platform Display At The Same Time</p>
        <p class="mt-2 text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black">
          Have you ever encountered an embarrassing error due to an inaccurate translation? Have you ever been in a situation where the translated content is very different from the original text? Simultaneous presentation of multi-platform translation solves these problems, where you can see multiple translations at the same time for you to choose from.
        </p>
      </v-container>
      <v-row align="center" no-gutters v-else>
        <v-col cols="6">
          <v-img src="@/assets/pic_logo.jpg" height="300" contain ></v-img>
        </v-col>
        <v-col cols="6">
          <p class="my-custom-font-explain text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 font-weight-black">Multi-Translation Platform Display At The Same Time</p>
          <p class="mt-5 text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black">
            Have you ever encountered an embarrassing error due to an inaccurate translation? Have you ever been in a situation where the translated content is very different from the original text? Simultaneous presentation of multi-platform translation solves these problems, where you can see multiple translations at the same time for you to choose from.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <!-- 2--收藏功能 -->
    <v-container class="my-10 py-5">
      <v-container class="d-flex justify-center flex-wrap text-center" v-if="$vuetify.breakpoint.smAndDown">
        <v-img src="@/assets/pic_star.png" height="150" contain></v-img>
        <p class="my-custom-font-explain text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 font-weight-black mt-2">Collect Your Favorite Phrases</p>
        <p class="mt-2 text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black">
          We provide a collection service, and you can find your collection from the "Star" navigation label. You can collect the content of your favorite translation so that you can find it quickly next time.
        </p>
      </v-container>
      <v-row align="center" no-gutters v-else>
        <v-col cols="5" offset="1">
          <p class="my-custom-font-explain text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 font-weight-black">Collect Your Favorite Phrases</p>
          <p class="mt-2 text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black">
            We provide a collection service, and you can find your collection from the "Star" navigation label. You can collect the content of your favorite translation so that you can find it quickly next time.
          </p>
        </v-col>
        <v-col cols="6">
          <v-img src="@/assets/pic_star.png" height="300" contain ></v-img>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <!-- 3--多翻译语种 -->
    <v-container class="my-10 py-5" >
      <v-container class="d-flex justify-center flex-wrap text-center" v-if="$vuetify.breakpoint.smAndDown">
        <v-img src="@/assets/pic_translate.png" height="150" contain></v-img>
        <p class="my-custom-font-explain text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 font-weight-black mt-2">Many Languages For You To Choose</p>
        <p class="mt-2 text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black">
          Have you ever encountered a translation function and find that there is no kind of language you want. We have many languages for you to choose from, so that you can enjoy the convenience of switching between languages.
        </p>
      </v-container>
      <v-row align="center" no-gutters v-else>
        <v-col cols="6">
          <v-img src="@/assets/pic_translate.png" height="300" contain ></v-img>
        </v-col>
        <v-col cols="6">
          <p class="my-custom-font-explain text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 font-weight-black">Many Languages For You To Choose</p>
          <p class="mt-5 text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black">
            Have you ever encountered a translation function and find that there is no kind of language you want. We have many languages for you to choose from, so that you can enjoy the convenience of switching between languages.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <!-- Q&A -->
    <v-container class="mt-16">
      <h2 class="text-center mb-10">Q&A</h2>
      <v-card v-for="item in QAitems" :key="item.key" class="mx-auto ma-6" width="100%">
        <v-container class="d-flex my-1">
          <v-icon class="mx-2" color="pink darken-4">mdi-chat-question</v-icon>
          <h4 class="ml-4">{{item.question}}</h4>
        </v-container>
        <v-divider inset></v-divider>
        <v-container class="d-flex my-1">
          <v-icon class="mx-2" color="indigo darken-2">mdi-forum</v-icon>
          <p class="ml-4">{{item.answer}}</p>
        </v-container>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import { requestPost } from '@/utils/SystemUtils/request.js';

export default {
  name: 'HomeView',

  components: {
    
  },

  data() {
    return {
      userID: '',
      token: '',
      isLogin: false,

      QAitems: [
        { 'key': 'key1', 'question': 'What is the purpose of multi -platform translation?', 'answer': "We are committed to solving the accuracy of translation, and the translation tone of different scenarios. Therefore, we hope to translate through multiple platforms at the same time, giving users the opportunity to compare translation content, so that users can get translation closer to the original content."},
        { 'key': 'key2', 'question': 'Why is it not displayed as a Vip user after payment?', 'answer': "If you do not successfully activate VIP after payment, please contact us immediately, we will handle it for you within 1 working day, please understand the inconvenience."},
        { 'key': 'key3', 'question': 'What is the trial period limit?', 'answer': "The multitranslate translation function needs the support of all users to become better. Multitranslate will be used for free within 31 days from the date of your registration. After that, you need to become a Pro user to continue using it."},
      ],
    }
  },

  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;
  },

  mounted() {
  },

  methods: {
    handleTranslate() {
      // this.$router.replace({
      //   name: 'All'
      // })
      const url='/UserMainTranslate';
      if (this.$route.path !== url) {
        this.$router.push({ path: url});
      }
    },
    handleContact() {
      // this.$router.replace({
      //   name: 'about'
      // })
      const url='/UserMainContact';
      if (this.$route.path !== url) {
        this.$router.push({ path: url});
      }
    }
  }
};
</script>
