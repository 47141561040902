<template>
  <v-container>
    <v-container class="d-flex justify-center mt-16 flex-wrap">
      <v-img src="@/assets/pic_email.png" height="300" contain sm="12" md="8" lg="8" xl="8"></v-img>
      <v-container class="d-flex justify-center align-center flex-wrap">
        <p class="text-center mt-4 my-custom-font-explain text-body-1 text-sm-body-1 text-md-body-1 text-lg-h6 text-xl-h6 font-weight-black">
          We would love to hear from you! If you have any questions, comments, or suggestions, please do not hesitate to contact us using the Email address below:
        </p>
        <div class="email ml-4"> <a class="text-h5" href="mailto:hello@receivesms.io">hello@promptboom.org</a> </div>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import { requestPost } from '@/utils/SystemUtils/request.js';

export default {
  name: 'ContactView',

  components: {
  },

  data() {
    return {
      userID: '',
      token: '',
      isLogin: false,
    }
  },

  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;
  },

  mounted() {
  },

  methods: {

  }
};
</script>

<style scoped>
  .my-custom-font-explain {
    font-family: 'Geologica', sans-serif;
  }
</style>