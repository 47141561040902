import request from '@/utils/SystemUtils/translate_request.js'
// import request_local from '@/utils/request_local'

export function translatefull(data) {
  return request({
    url: '/translatefull',
    method: 'post',
    data: data,
  })
}
export function usermessagetable(data) {
  return request({
    url: '/requestGetUserMsg',
    method: 'post',
    data: data,
  })
}
export function userstarmessagetable(data) {
  return request({
    url: '/userstarmessagetable',
    method: 'post',
    data: data,
  })
}
export function moderate(data) {
  return request({
    url: '/moderate',
    method: 'post',
    data: data,
  })
}

export function sendVerificationCode(data) {
  return request({
    url: '/requestSendVerifyEmail',
    method: 'post',
    data: data,
  })
}