<template>
  <div>
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import { requestPost } from '@/utils/SystemUtils/request.js';

export default {
  name: 'BeginView',

  components: {
  },

  data() {
    return {
      factoryID: '',
      isLogin: false,
    }
  },

  created() {
  },

  mounted() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        if (this.isLogin) {
          this.handleGetUserMsg();
        }
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;

    if (getLocalStorage('multitranslateUser') && getLocalStorage('multitranslateToken')) {
      this.$store.commit('HandleuserID', getLocalStorage('PromptBoomUser'));
      this.$store.commit('Handletoken', getLocalStorage('PromptBoomToken'));
      this.$store.commit('HandleisLogin', true);
    }
  },

  methods: {
    async handleGetUserMsg() {
      let request_header = {
        email: getLocalStorage('multitranslateUser'),
        token: getLocalStorage('multitranslateToken')
      };
      let request_json = {};

      let request_url = '/requestGetUserMsg';
      const res = await requestPost(request_header, request_json, request_url);
      if (res.statusCode == 1) {
        this.$store.commit('HandleuserName', res.data[0].userName);
        let isProUser=false
        const subscribeTime=new Date(res.data[0].subscribeTime*1000)

        const now=new Date()
        const oneMonthAgo=new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        if (subscribeTime >= oneMonthAgo) {
          isProUser=true
        } else {
          isProUser=false
        }

        this.$store.commit('HandleisProUser', isProUser);

        this.$store.commit('HandletranslateNum', res.data[0].todayCreditBalance);
        this.$store.commit('HandleSnackbar', 'Get message successed');

      } else {
        this.$store.commit('HandleSnackbar', 'Failed to retrieve user information:' + res.statusInfo);
      }
    },

  }
};
</script>
