<template>
  <v-container>
    <!-- 标头 -->
    <v-container class="text-center">
      <p class="text-center mt-4 my-custom-font-explain text-h5 text-sm-h5 text-md-h4 text-lg-h3 text-xl-h3 font-weight-black">
        Pricing
      </p>
      <p class="text-center mt-4 my-custom-font-explain text-body-1 text-sm-body-1 text-md-body-1 text-lg-h6 text-xl-h6 font-weight-black">
        Choose a plan that is suitable for your needs. If you want more search times, select the Pro plan.
      </p>
    </v-container>
    <v-container v-if="$vuetify.breakpoint.smAndDown">
      <v-card elevation="2" outlined>
        <p class="my-custom-font-explain deep-purple--text text--darken-4 text-h6 text-sm-h6 text-md-h5 text-lg-h5 text-xl-h5 font-weight-black mt-4 pt-4 text-center">Free</p>
        <v-container class="d-flex justify-center">
          <p class="my-custom-font-explain black--text text-h6 text-sm-h6 text-md-h5 text-lg-h5 text-xl-h5 font-weight-black text-center">$0</p>
          <p class="my-custom-font-explain grey--text text--darken-2 text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 mt-2 text-center">/month</p>
        </v-container>
        <p class="my-custom-font-explain grey--text text--darken-2 text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 text-center mt-n4">All basic features included</p>
        <v-expansion-panels class="my-6" multiple flat v-model="panelModel">
          <v-expansion-panel  v-for="item in items" :key="item.header">
            <v-divider></v-divider>
            <v-expansion-panel-header disable-icon-rotate>
              <p class="grey--text text--darken-3 text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 my-custom-font-explain">{{ item.header }}</p>
              <template v-slot:actions>
                <v-icon>
                  $expand
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-left black--text font-weight-black text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 my-custom-font-explain">
              {{ item.content }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-container class="my-2 px-5 d-flex justify-center">
          <v-btn color="black" width="100%" class='mx-auto white--text text-body-2 text-sm-body-2 text-md-body-1 text-lg-h6 text-xl-h6 font-weight-black' @click="handleTranslate">Get Started</v-btn>
        </v-container>
      </v-card>
      <v-card elevation="2" outlined class="mt-6">
        <p class="my-custom-font-explain deep-purple--text text--darken-4 text-h6 text-sm-h6 text-md-h5 text-lg-h5 text-xl-h5 font-weight-black mt-4 pt-4 text-center">Pro</p>
        <v-container class="d-flex justify-center">
          <p class="my-custom-font-explain grey--text text--darken-2 text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black mt-2 text-center text-decoration-line-through">$14.99</p>
          <p class="my-custom-font-explain black--text text-h6 text-sm-h6 text-md-h5 text-lg-h5 text-xl-h5 font-weight-black text-center ml-2">$9.99</p>
          <p class="my-custom-font-explain grey--text text--darken-2 text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 mt-2 text-center">/month</p>
        </v-container>
        <p class="my-custom-font-explain grey--text text--darken-2 text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 text-center mt-n4">All basic features included</p>
        <v-expansion-panels class="my-6" multiple flat v-model="panelModel">
          <v-expansion-panel  v-for="item in Proitems" :key="item.header">
            <v-divider></v-divider>
            <v-expansion-panel-header disable-icon-rotate>
              <p class="grey--text text--darken-3 text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 my-custom-font-explain">{{ item.header }}</p>
              <template v-slot:actions>
                <v-icon color="teal">
                  mdi-check
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-left black--text font-weight-black text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 my-custom-font-explain">
              {{ item.content }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-container class="my-2 px-5 d-flex justify-center">
          <v-btn color="black" width="100%" class='mx-auto white--text text-body-2 text-sm-body-2 text-md-body-1 text-lg-h6 text-xl-h6 font-weight-black' @click='handleSubscribe' :loading="handleSubscribeLoading">
            Try Now
          </v-btn>
        </v-container>
      </v-card>
    </v-container>
    <v-container v-else>
      <v-row>
        <!-- 免费信息栏 -->
        <v-col cols="6" align="center">
          <v-card elevation="2" outlined width="80%">
            <p class="my-custom-font-explain deep-purple--text text--darken-4 text-h6 text-sm-h6 text-md-h5 text-lg-h5 text-xl-h5 font-weight-black mt-4 pt-4 text-center">Free</p>
            <v-container class="d-flex justify-center">
              <p class="my-custom-font-explain black--text text-h6 text-sm-h6 text-md-h5 text-lg-h5 text-xl-h5 font-weight-black text-center">$0</p>
              <p class="my-custom-font-explain grey--text text--darken-2 text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 mt-2 text-center">/month</p>
            </v-container>
            <p class="my-custom-font-explain grey--text text--darken-2 text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 text-center mt-n4">All basic features included</p>
            <v-expansion-panels class="my-6" multiple flat v-model="panelModel">
              <v-expansion-panel  v-for="item in items" :key="item.header">
                <v-divider></v-divider>
                <v-expansion-panel-header disable-icon-rotate>
                  <p class="grey--text text--darken-3 text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 my-custom-font-explain">{{ item.header }}</p>
                  <template v-slot:actions>
                    <v-icon>
                      $expand
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="text-left black--text font-weight-black text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 my-custom-font-explain">
                  {{ item.content }}
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-container class="my-2 px-5 d-flex justify-center">
              <v-btn color="black" width="100%" class='mx-auto white--text text-body-2 text-sm-body-2 text-md-body-1 text-lg-h6 text-xl-h6 font-weight-black' @click="handleTranslate">Get Started</v-btn>
            </v-container>
          </v-card>
        </v-col>
        <!-- 付费信息栏 -->
        <v-col cols="6" align="center">
          <v-card elevation="2" outlined width="80%">
            <p class="my-custom-font-explain deep-purple--text text--darken-4 text-h6 text-sm-h6 text-md-h5 text-lg-h5 text-xl-h5 font-weight-black mt-4 pt-4 text-center">Pro</p>
            <v-container class="d-flex justify-center">
              <p class="my-custom-font-explain grey--text text--darken-2 text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black mt-2 text-center text-decoration-line-through">$14.99</p>
              <p class="my-custom-font-explain black--text text-h6 text-sm-h6 text-md-h5 text-lg-h5 text-xl-h5 font-weight-black text-center ml-2">$9.99</p>
              <p class="my-custom-font-explain grey--text text--darken-2 text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 mt-2 text-center">/month</p>
            </v-container>
            <p class="my-custom-font-explain grey--text text--darken-2 text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 text-center mt-n4">All basic features included</p>
            <v-expansion-panels class="my-6" multiple flat v-model="panelModel">
              <v-expansion-panel  v-for="item in Proitems" :key="item.header">
                <v-divider></v-divider>
                <v-expansion-panel-header disable-icon-rotate>
                  <p class="grey--text text--darken-3 text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 my-custom-font-explain">{{ item.header }}</p>
                  <template v-slot:actions>
                    <v-icon color="teal">
                      mdi-check
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="text-left black--text font-weight-black text-body-2 text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 my-custom-font-explain">
                  {{ item.content }}
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-container class="my-2 px-5 d-flex justify-center">
              <v-btn color="black" width="100%" class='mx-auto white--text text-body-2 text-sm-body-2 text-md-body-1 text-lg-h6 text-xl-h6 font-weight-black' @click='handleSubscribe' :loading="handleSubscribeLoading">
                Try Now
              </v-btn>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-img src="@/assets/pic_pricing.png" width="100%" contain></v-img>
    </v-container>
  </v-container>
</template>

<script>
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import { requestPost } from '@/utils/SystemUtils/request.js';

export default {
  name: 'PricingView',

  components: {
  },

  data() {
    return {
      userID: '',
      token: '',
      isLogin: false,
      isProUser: false,

      items: [
        {'panelsModel': true, 'header': 'Translation Engine', 'content':'All Engine'},
        {'panelsModel': true, 'header': 'Support Language', 'content': 'All Language'},
        {'panelsModel': true, 'header': 'Translation Function', 'content': '10 times a day'},
        {'panelsModel': true, 'header': 'Star', 'content': 'Including'},
      ],
      Proitems:[
        {'panelsModel': true, 'header': 'Translation Engine', 'content':'All Engine'},
        {'panelsModel': true, 'header': 'Support Language', 'content': 'All Language'},
        {'panelsModel': true, 'header': 'Translation Function', 'content': '√ Unlimited'},
        {'panelsModel': true, 'header': 'Star', 'content': 'Including'},
      ],
      panelModel: [0,1,2,3],

      handleSubscribeLoading: false,

    }
  },

  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;

    this.$store.watch(
      (state) => state.userMsg.isProUser, () => {
        this.isProUser = this.$store.getters.getisProUser;
      }, {
        deep: true
      }
    );
    this.isProUser = this.$store.getters.getisProUser;
  },

  mounted() {
  },

  methods: {
    handleTranslate() {
      const url='/UserMainTranslate';
      if (this.$route.path !== url) {
        this.$router.push({ path: url});
      }
    },

    async handleSubscribe() {
      // console.log("Oh My God, You are so kind.")
      this.handleSubscribeLoading = true;

      if (this.isProUser) {
        this.$store.commit('HandleSnackbar', 'You have already subscribed to PromptBoom 😉');
        this.handleSubscribeLoading = false;
      } else {
        if (this.isLogin) {
          let request_header = {
            email: getLocalStorage('multitranslateUser'),
            token: getLocalStorage('multitranslateToken')
          };
          let request_json = {};

          let request_url = '/requestSubscribe';
          const res = await requestPost(request_header, request_json, request_url);

          if (res.statusCode == 1) {
            window.location.href = res.data.sessionURL
            this.handleSubscribeLoading = false;
          } else {
            this.$store.commit('HandleSnackbar', 'Failed' + res.statusInfo);
            this.handleSubscribeLoading = false;
          }
        } else {
          this.$store.commit('HandleSnackbar', 'Please Login');
          this.handleSubscribeLoading = false;

        }
      }
    }
  }
}
</script>
