<template>
  <div>
    <v-container>
      <!-- 翻译信息栏 -->
      <v-card color="rgb(60, 44, 129)" width="100%">
        <v-menu>
            <template v-slot:activator="{ on }">
                <v-btn text v-on="on" class="text-center">
                    <span class="mr-2 text-capitalize white--text">{{ inputLanguage }}</span>
                    <v-icon color="white" v-if="!$vuetify.breakpoint.smAndDown">mdi-chevron-down</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item v-for="lang in supportedLang" :key="lang" @click="inputLanguage = lang">
                    <v-list-item-title>{{lang}}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-btn @click="handleLanguagechange" icon>
            <v-icon color="white">mdi-swap-horizontal</v-icon>
        </v-btn>
        <v-menu>
            <template v-slot:activator="{ on }">
                <v-btn text v-on="on" class="text-center">
                    <span class="mr-2 text-capitalize white--text">{{ translateLanguage }}</span>
                    <v-icon color="white" v-if="!$vuetify.breakpoint.smAndDown">mdi-chevron-down</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item v-for="lang in supportedLang" :key="lang" @click="translateLanguage = lang" v-show="true?lang!='Auto Detect':false">
                    <v-list-item-title>{{lang}}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
      </v-card>
      <!-- 翻译文本框 -->
      <v-textarea ref="textarea" solo outlined autofocus label="Enter Text" v-model="text" auto-grow clearable clear-icon="mdi-close-circle" maxlength="500" @keypress.enter.exact="handleTranslate" class="my-custom-font-explain text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black">
          <template v-slot:append>
              <v-btn class="primary ma-2" fab small @click="handleTranslate">
                  <v-icon>mdi-send-variant</v-icon>
              </v-btn>
          </template>
      </v-textarea>
      <!-- 翻译内容展示栏 -->
      <v-card v-for="item in supportedTranslator" :key="item.translator" :loading="item.loading==1? 'rgb(60, 44, 129)':false" hover class="mx-auto ma-4" width="100%">
        <v-container class="d-flex align-center">
            <v-img :alt="item.translator" width="20" class="shrink ma-2" contain :src="item.logo" transition="scale-transition" />
            <div class="font-weight-black ml-2">{{ item.translator_alias }}</div>
            <v-spacer></v-spacer>
            <v-btn @click="handleCopy(item.translateText)" class="ml-2 mr-2" icon>
                <!-- <v-btn @click="handleCopy('hi')" class="ml-2 mr-2" icon> -->
                <v-icon color="black">mdi-content-copy</v-icon>
            </v-btn>
            <v-btn @click="handleStar(item)" class="ml-2 mr-2" icon>
                <v-icon color="black">{{ item.isStar==1? 'mdi-star' : 'mdi-star-outline' }}</v-icon>
            </v-btn>
        </v-container>
        <v-container class="d-flex align-center justify-center" v-if="item.isProTranslator && !IsPorUser">
            <!--                 <v-btn small color="rgb(60, 44, 129)" @click="handlePricing" class="mb-2">
                <span class="ma-2 text-capitalize white--text">Upgrade plan</span>
            </v-btn> -->
            <PayLink />
        </v-container>
        <v-container v-else>
            <div class="mb-2 ml-2 mr-2 my-custom-font-explain text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black" :class="{ 'red--text': item.statusCode }">{{ item.translateText }}</div>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import { requestPost } from '@/utils/SystemUtils/request.js';
import PayLink from '@/components/PayLink/index.vue';
import { translatefull, userstarmessagetable, usermessagetable, moderate } from '@/api/tinytranslateapi';


export default {
  name: 'TranslateView',

  components: {
    PayLink
  },

  data() {
    return {
      userID: '',
      token: '',
      isLogin: false,
      isProUser: false,
      translateNum: 0,

      text: "",
      inputLanguage: "",
      translateLanguage: "",
      IsLogin: false,
      UserId: "",
      IsPorUser: false,
      supportedLang: ['Auto Detect', 'Arabic', 'Cantonese', 'Chinese(Simplified)', 'Chinese(Traditional)', 'English', 'Filipino', 'French', 'German', 'Japanese', 'Indonesian', 'Italian', 'Korean', 'Malay', 'Portuguese', 'Russian', 'Spanish', 'Thai', 'Vietnamese'],
      supportedTranslator: [
        { translator: 'google', translator_alias: 'Google', isProTranslator: false, loading: false, logo: require('@/assets/google.png'), translateText: '', isStar: 0, starID: '', statusCode: true },
        { translator: 'bing', translator_alias: 'Bing', isProTranslator: false, loading: false, logo: require('@/assets/bing.png'), translateText: '', isStar: 0, starID: '', statusCode: true },
        // { translator: 'alibaba', translator_alias: 'Alibaba', isProTranslator: false, loading: false, logo: require('@/assets/alibaba.png'), translateText: '', isStar: 0, starID: '', statusCode: true },
        // { translator: 'baidu', translator_alias: 'Baidu', isProTranslator: falsee, loading: false, logo: require('@/assets/baidu.png'), translateText: '', isStar: 0, starID: '',statusCode:true },
        // { translator: 'itranslate', translator_alias: 'iTranslate', isProTranslator: falsee, loading: false, logo: require('@/assets/itranslate.svg'), translateText: '', isStar: 0, starID: '',statusCode:true },
        // { translator: 'deepl', translator_alias: 'Deepl', isProTranslator: false, loading: false, logo: require('@/assets/deepl.png'), translateText: '', isStar: 0, starID: '', statusCode: true },
        { translator: 'youdao', translator_alias: 'Youdao', isProTranslator: false, loading: false, logo: require('@/assets/youdao.png'), translateText: '', isStar: 0, starID: '', statusCode: true },
        { translator: 'qqFanyi', translator_alias: 'Tencent', isProTranslator: false, loading: false, logo: require('@/assets/tencent.png'), translateText: '', isStar: 0, starID: '', statusCode: true },
        { translator: 'papago', translator_alias: 'Papago', isProTranslator: false, loading: false, logo: require('@/assets/papago.svg'), translateText: '', isStar: 0, starID: '', statusCode: true }
      ],
      starIconOn: "el-icon-star-on",
      starIconOff: "el-icon-star-off",
    }
  },

  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;

    this.$store.watch(
      (state) => state.userMsg.isProUser, () => {
        this.isProUser = this.$store.getters.getisProUser;
      }, {
        deep: true
      }
    );
    this.isProUser = this.$store.getters.getisProUser;

    this.$store.watch(
      (state) => state.userMsg.translateNum, () => {
        this.translateNum = this.$store.getters.gettranslateNum;
      }, {
        deep: true
      }
    );
    this.translateNum = this.$store.getters.gettranslateNum;
  },

  mounted() {
    this.inputLanguage = 'Auto Detect';
    this.translateLanguage = "English";
  },

  methods: {
    handleLanguagechange() {
      if (this.inputLanguage != 'Auto Detect') {
        const midLanguage = this.inputLanguage;
        this.inputLanguage = this.translateLanguage;
        this.translateLanguage = midLanguage;
      }
    },

    handleCopy(translateText) {
      if (translateText.length > 0) {
        this.$copyText(translateText).then(e => {
          this.$store.commit('HandleSnackbar', 'Copied');
        }, function(e) {
          this.$store.commit('HandleSnackbar', 'error');
        })
      }
    },

    handleTranslate() {
      if (this.text.length > 0 && (this.inputLanguage != this.translateLanguage)) {
        if (this.isLogin) {
          if (this.translateNum > 0) {
            this.handleNum()
            this.handleLogs()
            let translaterequest = {};
            translaterequest.hsapihandle = "fnctranslatefull";
            translaterequest.query_text = this.text.trim();
            translaterequest.from_language = this.inputLanguage;
            translaterequest.to_languages = [this.translateLanguage];
            translaterequest.translators = [];

              // 分开请求
            for (let item of this.supportedTranslator) {
              if (this.IsPorUser || !item.isProTranslator) {
                translaterequest.translators = [item.translator];
                item.loading = true;
                translatefull(translaterequest)
                  .then((res) => {
                    // console.log(res)
                    if (res.error_code == 1) {
                      let message = res['data']["result"].translateresult;
                      this.$store.commit('HandleSnackbar', 'error');
                    } else {
                      for (let item of res['data']["result"].translateresult) {
                        for (let item2 of this.supportedTranslator) {
                          if (item.translator == item2.translator) {
                            item2.translateText = item.final_text;
                            item2.isStar = false;
                            item2.starID = '';
                            if (item.status_code != 1) {
                              item2.statusCode = true;
                            } else {
                              item2.statusCode = false;
                            }
                          }
                          }
                      }
                    }
                  })
                  .catch(() => {
                    this.$store.commit('HandleSnackbar', 'Network Error');
                  })
                  .finally(() => {
                      item.loading = false;
                  });
              }
            }
          } else {
            this.$store.commit('HandleSnackbar', 'Your trial period has expired, please upgrade to pro user');
          }
        } else {
          this.$store.commit('HandleSnackbar', 'Please login');
        }


      }
    },

    async handleNum() {
      let request_header = {
        email: getLocalStorage('multitranslateUser'),
        token: getLocalStorage('multitranslateToken')
      };
      let request_json = {
        todayCreditBalance: this.translateNum - 1,
      };

      let request_url = '/requestUpdateUserMsg';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        this.$store.commit('HandletranslateNum', this.translateNum - 1);
      }
    },

    async handleLogs() {
      let request_header = {
        email: getLocalStorage('multitranslateUser'),
        token: getLocalStorage('multitranslateToken')
      };
      let request_json = {
        queryText: this.text.trim(),
        fromLanguage: this.inputLanguage,
        toLanguage: [this.translateLanguage]
      };

      let request_url = '/requestAddLogs';
      const res = await requestPost(request_header, request_json, request_url);
    },

    async handleStar(item) {
      if (item.translateText.length > 0) {
        if (this.isLogin) {
          if (item.isStar == 0) {
            item.loading = true;
            let request_header = {
              email: getLocalStorage('multitranslateUser'),
              token: getLocalStorage('multitranslateToken')
            };
            let request_json = {
              StarId: uuidv4(),
              StarTime: new Date().toLocaleString(),
              InputLanguage: this.inputLanguage,
              TranslateLanguage: this.translateLanguage,
              InputText: this.text.trim(),
              TranslateText: item.translateText,
              TranslateTool: item.translator_alias,
            };
            item.starID = request_json.StarId;

            let request_url = '/requestAddStarMsg';
            const res = await requestPost(request_header, request_json, request_url);

            if (res.statusCode == 1) {

              this.$store.commit('HandleSnackbar', 'Success');
              item.isStar = 1;
              item.loading = false;
            } else {
              this.$store.commit('HandleSnackbar', 'Failed to star:' + res.statusInfo);
            }
          } else {
            item.loading = true;
            let request_header = {
              email: getLocalStorage('multitranslateUser'),
              token: getLocalStorage('multitranslateToken')
            };
            let request_json = {
              StarId: item.starID
            };

            let request_url = '/requestDeleteStarMsg';
            const res = await requestPost(request_header, request_json, request_url);

            if (res.statusCode == 1) {
              item.loading = false;
              item.isStar = 0;
            } else {
              this.$store.commit('HandleSnackbar', 'Failed to delete:' + res.statusInfo);
            }
          }
        } else {
          this.$store.commit('HandleSnackbar', 'Please Login');
        }
      }
    },
    
  }
};
</script>
