<template>
  <v-app>
    <YA_Snackbar ref="YA_SnackbarRef" />
    <TopView />
    <YA_Loading />


    <v-main>
      <keep-alive>
        <router-view />
      </keep-alive>
    </v-main>

    <v-footer padless>
      <YA_footer />
    </v-footer>
  </v-app>
</template>

<script>
import YA_Snackbar from '@/components/SocialComponents/YA_Snackbar.vue';
import YA_Loading from '@/components/SocialComponents/YA_Loading.vue';
import TopView from '@/views/SocialView/TopView.vue';
import YA_footer from '@/components/SocialComponents/YA_footer.vue';

import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'

export default {
  name: 'App',

  components: {
    YA_Snackbar,
    YA_Loading,
    TopView,
    YA_footer,
  },

  data() {
    return {
    }
  },

  created() {
    this.$store.watch(
      (state) => state.systemMsg.Snackbar, () => {
        if (this.$store.getters.getSnackbar !== '') {
          this.$refs.YA_SnackbarRef.handleSnackbar(this.$store.getters.getSnackbar);
          this.$store.commit('HandleSnackbar', '');
        }
      }, {
        deep: true
      }
    );

  },

  mounted() {
  },

  methods: {
    handleAppSnackbar() {
      this.$refs.YA_SnackbarRef.handleSnackbar('Failed to retrieve user information:');
    }
  }
};
</script>
