import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "@/store/index"; 

// import AppView from '@/App.vue';

import BeginView from '@/views/BeginView.vue';

import HomeView from '@/views/FuncView/HomeView.vue';
import TranslateView from '@/views/FuncView/TranslateView.vue';
import StarView from '@/views/FuncView/StarView.vue';
import PricingView from '@/views/FuncView/PricingView.vue';
import ContactView from '@/views/FuncView/ContactView.vue';

import Login from '@/components/SocialComponents/YA_Login.vue';


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'BeginView',
    component: BeginView,
    redirect: 'UserMainHome',
    meta:{
      'title':'multitranslate'
    },
    children: [
      {
        path: 'Login',
        name: 'Login',
        component: Login,
        meta:{
          'title':'Login - multitranslate'
        },
      },
      {
        name: 'UserMainHome',
        path: 'UserMainHome',
        component: HomeView,
        meta: {
            'title': 'Home - MultiTranslate'
        }
    },
    {
        name: 'UserMainTranslate',
        path: 'UserMainTranslate',
        component: TranslateView,
        meta: {
            'title': 'Translate - MultiTranslate'
        }
    },
    {
        name: 'UserMainStar',
        path: 'UserMainStar',
        component: StarView,
        meta: {
            'title': 'Star - MultiTranslate'
        }
    },
    {
        name: 'UserMainPricing',
        path: 'UserMainPricing',
        component: PricingView,
        meta: {
            'title': 'Pricing - MultiTranslate'
        }
    },
    {
        name: 'UserMainContact',
        path: 'UserMainContact',
        component: ContactView,
        meta: {
            'title': 'Contact - MultiTranslate'
        }
    },
    ]
  },
  
  {
    path:"*",
    redirect:'/UserMainHome'
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to,from,next)=>{
  if(to.meta.title){
    document.title=to.meta.title
  }

  next()
});




export default router;
