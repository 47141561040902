<template>
  <div>
    <v-app-bar app elevation="1" height="66">
      <!-- logo -->
      <div class="d-flex align-center">
        <router-link to="/" class='text-decoration-none' v-if="$vuetify.breakpoint.smAndDown">
          <v-img alt="MultiTranslate Logo" class="shrink mr-2" contain src="@/assets/multitranslate.png" transition="scale-transition" width="200" />
        </router-link>
        <router-link to="/" class='text-decoration-none' v-else>
          <v-img alt="MultiTranslate Logo" class="shrink mr-2" contain src="@/assets/multitranslate.png" transition="scale-transition" width="200" />
        </router-link>
      </div>
      <v-spacer></v-spacer>
      <!-- 导航栏 -->
      <div v-if="$vuetify.breakpoint.smAndDown">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on" class="text-center">
              <span class="mr-2 text-capitalize">Menu</span>
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in menus" :key="index">
              <router-link :to="item.path" class='text-decoration-none black--text'>
                <v-list-item-title>{{item.name}}</v-list-item-title>
              </router-link>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div v-else>
        <router-link :to='item.path' class='text-decoration-none' v-for="(item, index) in menus" :key="index">
          <v-btn text class="text-center mx-1">
            <span class="text-capitalize font-weight-black text-body-1">{{item.name}}</span>
          </v-btn>
        </router-link>
      </div>

      <div class="d-flex align-center mx-2">
        <YA_User v-if="isLogin" class="mx-3" />
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="grey-darken" v-bind="attrs" v-on="on" small class="mx-3" icon @click="handleLogin">
              <v-icon size="30" color="grey darken-3">mdi-account-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>Login</span>
        </v-tooltip>
      </div>
      <!-- 登录与用户信息 -->
      <!-- <div v-show="isShown">
        <v-btn v-if="!IsLogin" color="primary" class="text-center" @click="login_dialog = true" :loading="LoginLoading">
          <span class="text-capitalize">Login</span>
        </v-btn>
        <v-menu offset-y no-border v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar v-on="on" v-bind="attrs" class="mr-2 ml-2" size="32">
              <v-img alt="avatar" contain :src="photoUrl" />
            </v-avatar>
          </template>
          <v-card flat>
            <v-row class="d-flex ma-2">
              <v-col>
                <v-avatar size="46">
                  <v-img alt="avatar" contain :src="photoUrl" />
                </v-avatar>
              </v-col>
              <v-col class="flex-grow-1 d-flex align-center">
                <h6>{{ displayName }}<br>{{ UserId }}</h6>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <div>
              <div class="d-flex ma-4 align-center justify-center" v-if="IsPorUser">
                <v-icon color="amber accent-4">mdi-key-chain</v-icon>
                <h5 class="text-center">You are already Pro user</h5>
              </div>
              <div class="ma-4" v-else>
                <h5 class="text-center">{{ userTrial }} days left in trial</h5>
                <v-container class="d-flex justify-center">
                  <v-btn  small color="rgb(60, 44, 129)"  @click='handlePricing'>
                    <span class="ma-2 text-capitalize white--text">Upgrade plan</span>
                  </v-btn>
                </v-container>
              </div>
            </div>
            <v-divider></v-divider>
            <div class="d-flex ma-4 align-center justify-center">
              <v-btn small color="grey darken-4" @click="handleLogout">
                <span class="ma-2 text-capitalize white--text">Sign out</span>
              </v-btn>
            </div>
          </v-card>
        </v-menu>
      </div> -->
      <!-- 登录窗口 -->
      <!-- <v-dialog v-model="login_dialog" max-width="500px">
        <v-card>
          <v-card-title class="d-flex align-center justify-center my-8">
            <p class="my-custom-font-explain text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 font-weight-black">MultiTranslate</p>
          </v-card-title>
          <v-card-text>
            <v-text-field
              label="Email Address"
              :rules="emailRules"
              hide-details="auto"
              v-model="emailAddress"
            ></v-text-field>
            <v-text-field label="Email verification code" v-model="InputCode">
              <v-btn color="black" slot="append" class='white--text mx-auto text-body-2 text-sm-body-2 text-md-body-1 text-lg-h6 text-xl-h6' :disabled="isFetching" @click='sendVerificationCode'>
                {{ isFetching ? `${countDown}s ` : "Get code" }}
              </v-btn>
            </v-text-field>
            <v-btn color="black" width="100%" class='mx-auto white--text text-body-2 text-sm-body-2 text-md-body-1 text-lg-h6 text-xl-h6 font-weight-black' @click='handleEmailLogin'>
              Continue with Email
            </v-btn>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="login_dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
    </v-app-bar>

    <!-- <v-system-bar height="66" app style="background-color: #439798;" class="d-flex justify-space-between">
      <div class="d-flex">
        <div class="ml-n2 d-flex align-center justify-space-between" style="width: 240px;"> 
          <span class="ml-6 white--text text--darken-4 text-h6 font-weight-black">快工序</span>
        </div>
      </div>
      
      <div class="d-flex align-center mx-2">
        <YA_User v-if="isLogin" />
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="grey-darken" v-bind="attrs" v-on="on" small class="mx-3" icon @click="handleLogin">
              <v-icon size="30" color="grey lighten-3">mdi-account-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>登录</span>
        </v-tooltip>
      </div>
    </v-system-bar> -->
  </div>
</template>

<script>
import YA_User from '@/components/SocialComponents/YA_User.vue';
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import { requestPost } from '@/utils/SystemUtils/request.js';

export default {
  name: 'TopView',

  components: {
    YA_User,
  },

  data() {
    return {
      isLogin: false,
      isProUser: false,

      menus: [{
        'name': 'Home',
        'path': '/'
      },
      {
        'name': 'Translate',
        'path': '/UserMainTranslate'
      },
      {
        'name': 'Star',
        'path': '/UserMainStar'
      },
      {
        'name': 'Pricing',
        'path': '/UserMainPricing'
      },
      {
        'name': 'Contact',
        'path': '/UserMainContact'
      }]
    }
  },

  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
  },

  methods: {
    handleLogin() {
      let url='';
      if (!this.isLogin) {
        url = '/Login'
      } else {
        url = '/Home'
      }
      if (this.$route.path !== url) {
        this.$router.push({ path: url});
      }
      
    },

  }
};
</script>

<style scoped>
</style>
