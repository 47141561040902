import axios from 'axios'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const service = axios.create({
  baseURL: 'https://multitranslateapi2.promptboom.org/api/',
  // baseURL: 'http://localhost:9001/',
  timeout: 10000
})

export default service
